import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import firebase from 'firebase/app';
import FirebaseAuth from './auth/FirebaseAuth'

const firebaseConfig = {
  apiKey: "AIzaSyCyrR3wDIOiOsMNt9-aSkWecL5R3GPOATo",
  authDomain: "learn.dvdkruk.com",
  databaseURL: "https://learning-platform-236308.firebaseio.com",
  projectId: "learning-platform-236308",
  storageBucket: "learning-platform-236308.appspot.com",
  messagingSenderId: "402034046159"
};
firebase.initializeApp(firebaseConfig);

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <small>Mode: <b>{process.env.NODE_ENV}</b></small>
          <small>Version: <b>{process.env.REACT_APP_VERSION}</b></small>
          <FirebaseAuth></FirebaseAuth>
        </header>
      </div>
    );
  }
}

export default App;