import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth'
import firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

class FirebaseAuth extends Component {
  uiConfig = {
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  state = {
    isSignedIn: undefined,
  };

  componentDidMount() {
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user });
      if (!user) {
        ui.start('#firebaseui-auth-container', this.uiConfig);
      }
    }, error => console.log(error));
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const user = firebase.auth().currentUser;

    return (<div>
      {user ? (
        <div>
          <h1>My App</h1>
          <p>Welcome {user.displayName}! You are now signed-in!</p>
          {user.photoURL && <img src={user.photoURL} alt="profileURL"></img>}
          <button onClick={() => firebase.auth().signOut()}>Sign-out</button>
        </div>
      ) : (
          <div>
            <div id="firebaseui-auth-container"></div>
          </div>
        )}
    </div>
    )
  }
}

export default FirebaseAuth